.branding {
    margin-top: 15px;
    margin-bottom: 15px;
    color: $gray-700 !important;
    
    h2 {
        color: $gray-700 !important;
    }
   
    .red-box {
        background-color: lighten($red, 30%);
        padding: 30px;
    }
    
    .branding-img {
        @include background-fixed('../images/branding.jpg');
        height: 100%;
        min-height: 200px;
    }
    
    .btn-lg {
        position: absolute;
        bottom: 0px;
    }
}