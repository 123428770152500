header {
    margin-bottom: 50px;
    
    .logo {
        width: 160px;
        height: 152px;
    }

    .brand-name {
        padding-top: 20px;

        h1 {
            margin-bottom: 0px !important;
        }
    }

    .header-moduls {
        padding-top: 20px;

        .usermenu {
            li {
                margin-right: 20px;

                a {
                    color: $gray-700;
                    font-size: 12px;
                    font-weight: 900 !important;
                    font-family: "Lato";
                }

                a::before {
                    font-family: "FontAwesome";
                    margin-right: 5px;
                }
            }
        }
    }

    .bg-light {
        background-color: transparent !important;
    }
    
    nav {
        margin-top: 25px;
        padding: 0px !important;
        font-size: 20px;
        font-weight: 900;
        background-color: transparent !important;

        li {
            line-height: 20px;
            padding-left: 100px;

            .nav-child {
                @include box-shadow(0px, 12px, 25px, rgba(0,0,0,0.2));
                margin-left: 100px;
                margin-top: 15px;
                width: 300px;

                li {
                    padding: 0px !important;

                    a {
                        border-left: 0px;
                        color: $blue;
                        padding:15px 15px 15px 15px !important;
                    }

                    .active {
                        background-color: transparent;
                        color: $red;
                        border-left: 4px solid $yellow;
                    }
                }
            }
        }

        a {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
            padding-left: 10px !important;
            padding-right: 0px !important;

        }

        .active {
            a {
                border-left: 20px solid $yellow;
            }
        }
    }

    @media (max-width: 1200px) {
        .logo {
            width: 120px;
            height: 114px;
        }

        .brand-name {
            padding-top: 10px;

            h1 {
                margin-bottom: 0px !important;
                font-size: 24px;
            }
        }
    }

    @media (max-width: 992px) {
        nav {

            li {
                padding: 15px;

                .nav-child {
                    @include box-shadow(0px, 0px, 0px, transparent);
                    border: 0px;
                    margin-left: 0px;
                }
            }
        }

        .logo {
            width: 100px;
            height: 95px;
        }

        .brand-name {
            padding-top: 10px;

            h1 {
                margin-bottom: 0px !important;
                font-size: 20px;
            }
        }
    }

    @media (max-width: 768px) {
        .logo {
            width: 80px;
            height: 76px;
        }

        .brand-name {
            padding-top: 10px;

            h1 {
                margin-bottom: 0px !important;
                font-size: 20px;
            }
        }
    }

    @media (max-width: 576px) {
        .logo {
            width: 60px;
            height: 57px;
        }

        .brand-name {
            padding-top: 10px;

            h1 {
                margin-bottom: 0px !important;
                font-size: 18px;
            }
        }
    }
}