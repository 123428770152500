footer {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: lighten($blue, 50%);
    color: $blue !important;
    margin-top: 50px;
    
    h3 {
        font-size: 22px;
    }
    
    a {
        color: $blue;
    }
    
    ul {
        li {
            margin-left: -20px;
            list-style: none;
            a {
                
            }
        }
        
        li::before {
            font-family: 'Fontawesome';
            content: '\f061 ';
            margin-right: 10px;
            color: $blue;
        }
    }
}