.top_hits_products {
    .col-lg-3 {
        position: relative;

        .image {
            height: 300px;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }

        .description-container {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            margin-left: 7.5px;
            margin-right: 7.5px;

            .name {
                padding: 40px;

                a {
                    color: #FFFFFF;
                    font-weight: 900;
                    font-size: 18px;
                }
            }

            .button-goup {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                padding-right: 15px;

                .jshop_price {
                    float: left;
                    background-color: $gray-300;
                    color: $gray-700;
                    padding: 0.375rem 0.75rem;
                }

                .button_detail {
                    float: right;
                    max-height: 36px;
                }
            }

        }
    }

    .carousel-control-prev {
        justify-content: left;
        margin-left: -25px;

        .carousel-control-prev-icon-container {
            background-color: $blue;
            @include borderRadius(25px);
            width: 50px;
            height: 50px;
            padding: 15px;
        }
    }

    .carousel-control-next {
        justify-content: right;
        margin-right: -25px;

        .carousel-control-next-icon-container {
            background-color: $blue;
            @include borderRadius(25px);
            width: 50px;
            height: 50px;
            padding: 15px;
        }
    }
}