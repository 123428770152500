.related_header {
    h4 {
        margin-bottom: 10px;
    }
}

.product-related {
    height: 300px;

    .image {
        position: relative;
        height: 100%;
        width: 100%;

        .info {
            position: absolute;
            bottom: 0px;
            left: 0px;
        }
    }
}