.product-details {
    .image_middle {
        .product_label {
            position: absolute;
            top: 20px;
            left: 0px;
            margin-left: 7.5px;
            z-index: 1;
        }


        img:first-child {
            width: 100%;
        }
    }

    .product-image {
        position: relative;
        margin-top: 15px;
        @extend .img-fluid;

        .text_zoom {


            .fa-search-plus {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -19px;
                margin-top: -19px;
                color: rgba(255,255,255,0);
                @include transition(0.3s);
            }
        }
    }
    
    .product-image:first-child {
        margin-top: 0px;
    }

    .product-image:hover {
        .fa-search-plus {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -19px;
            margin-top: -19px;
            color: rgba(255,255,255,0.8);
        }
    }


    .jshop_prod_description {
        
        
        @media (min-width: 576px) {
            padding-left: 30px;
        }

        h1 {
            font-size: 20px;
            color: $gray-700;
        }

        .old_price {
            font-size: 16px;
            color: $gray-400;
            text-decoration: line-through;
        }

        .prod_price {
            font-size: 28px;
            color: $gray-700;
            font-weight: 900;
            margin-bottom: 50px;
            
            .taxinfo {
                font-size: 16px;
                font-weight: 300;
            }
            
            .plusshippinginfo {
                @extend .taxinfo;
            }
        }
        
        .not_available {
            padding: 15px;
            background-color: lighten($red,30%);
            margin-bottom: 15px;
            border: 1px solid darken($red,20%);
            color: darken($red,20%);
        }
    }
    
    #quantity {
        max-width: 50px;
    }

    .btn-buy {
        background-color: $yellow;
        color: $blue;
        border: $yellow;
    }
    
    .jshop_prod_attributes {
        select {
            @extend .form-control;
        }
    }
    
    .deliverytime {
        margin-bottom: 40px;
    }

    .info {
        margin-top: 40px;
        margin-bottom: 40px;
        color: $info;
    }
}