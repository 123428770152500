.breadcrumb {
    background-color: transparent !important;
    padding: 0px !important;
    margin-top: 0px;
    
    li {
        font-size: 12px;
        background-color: transparent;
        margin-right: 10px;
        padding: 5px;
        border: 1px solid $gray-700;
        
        a {
            color: $gray-700;
        }
    }  
    
    .caret {
        border: 0px;
        color: $gray-700;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px !important;
        margin-right: 10px !important;
    }
    
    
    .active {
        background-color: $gray-300;
    }  
}

.breadcrumb-item + .breadcrumb-item::before {
    content: none !important;
}