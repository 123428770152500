.lohnarbeit {
    margin-top: 15px;
    color: $gray-700 !important;
    
    h2 {
        color: $gray-700 !important;
    }
   
    .yellow-box {
        background-color: lighten($yellow, 30%);
        padding: 30px;
    }
    
    .lohnarbeit-img {
        @include background-fixed('../images/lohnarbeit.jpg');
        height: 100%;
        min-height: 200px;
    }
    
    .btn-lg {
        position: absolute;
        bottom: 0px;
        right: 7.5px;
    }
}