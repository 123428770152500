.djslider-loader {
    margin-bottom: 15px !important;
    
    .slide-desc {
        .slide-desc-bg-default {
            background-color: transparent;
        }
        
        .slide-title {
            font-size: 50px;
            text-shadow: 0px 0px 20px #000000;
            
            @media (max-width: 768px) {
                font-size: 22px;
            }
        }
    }
    
    .navigation-container-custom {
        top: auto !important;
        bottom: 10px;
        
        .load-button {
            width: 20px;
            height: 20px;
            background-color: transparent;
            border: 2px solid $red;
        }
        
        .load-button-active {
            background-color: $red;
        }
    }
}
