#jshop_module_cart {
    font-size: 12px;

    .shopping-cart {
        color: $gray-600;

        #jshop_quantity_products {
            font-weight: 900;
        }   
    } 

    i {
        paddig-left: 50px;
    }
}

@media (max-width: 576px) {
    #jshop_module_cart {
        .text-right {
            text-align: left !important;
        }
    }
}