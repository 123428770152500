.block_product {
    margin-bottom: 15px !important;
}
.pro {
    margin-bottom: 15px;
    height: 364px;

    .product {
        position: relative;
        @include transition(0.2s);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 364px;
        width: 100%;

        .image {
            top: 0px;
            left: 0px;
            margin: 0px;
            height: 364px;
            position: relative;


            .image_block {
                padding: 0px;
            }

            .product_label {
                position: absolute;
                left: 0px;
                top: 20px;
                margin: 0px;
            }
        }

        .info-container-blur {
            @include transition(0.2s);
            @include opacity(0);
            position: absolute;
            top: 10px;
            left: 0%;
            width: 92%;
            height: 324px;
            margin: 20px 4% 20px 4%;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
        
        .info-container {
            @include transition(0.6s);
            @include opacity(0);
            padding: 30px;
            position: absolute;
            text-align: left !important;
            background: rgba(0,0,0,0.3);
            top: 0%;
            left: 0%;
            width: 90%;
            height: 324px;
            margin: 20px 5% 20px 5%;


            .name {
                text-align: left !important;

                a {
                    color: #FFFFFF;
                    font-weight: 900;
                    font-size: 20px;
                    text-decoration: none;
                }
            }

            .description {
                color: #FFFFFF;
                @media (max-width: 576px) {
                   font-size: 14px; 
                }
            }

            .oiproduct {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;

                .jshop_price {
                    text-align: left;
                    float: left;
                    margin: 0px;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    color: $gray-700;
                    background-color: $gray-300;
                }
                
                .not_available {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    background-color: #ff6666;
                    font-size: 1rem;
                    color:#ffffff;
                    width: 200px;
                    padding: 7.5px;
                }

                .price_extra_info {
                    float: left;
                }

                .old_price {
                    float: left;
                    margin: 0px;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    color: $gray-300;
                    background-color: $gray-700;
                    text-decoration: line-through;
                }

                .buttons {
                    float: right;
                    a {
                        max-height: 36px;
                        margin-top: 0px !important;
                    }
                }
            }


        }
    }
}
.product:hover{

    @include box-shadow(0px, 0px, 40px, lighten($blue, 50%));

    .info-container-blur {
        @include opacity(1);
        -webkit-filter: blur(10px);
        filter: url('/media/blur.svg#blur');
        filter: blur(10px);
    }

    .info-container {
        @include opacity(1);
    }

    .image {

    }
}