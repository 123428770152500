//Mixins
@import "mixins/box-shadow";
@import "mixins/borderRadius";
@import "mixins/background";
@import "mixins/transition";
@import "mixins/box-shadow";
@import "mixins/opacity";

//Joomla Moduls
@import "moduls/mod_jshopping_search";
@import "moduls/mod_jshopping_cart";
@import "moduls/mod_top_hits_products";
@import "moduls/mod_djimageslider";
@import "moduls/mod_jshopping_universal";

//Site Moduls
@import "moduls/header";
@import "moduls/breadcrumb";
@import "moduls/login";
@import "moduls/footer";
@import "moduls/lohnarbeit";
@import "moduls/branding";
@import "moduls/kat-list-product";
@import "moduls/product_default";
@import "moduls/cart";
@import "moduls/statusbar";
@import "moduls/com_baforms";
@import "moduls/product-related";

//Global
body {
    border-top: 6px solid $blue;
}

#mxcpr {
    display: none;
}

.jshopping-copy, .jshopping-copy:hover {
    color: $gray-300;
    font-size: 10px;
}

.margin-top {
    margin-top: 15px;
}

.margin-bottom {
    margin-bottom: 15px;
}

body {
    background-image: url('../images/made_in_germany.svg');
    background-size: 450px 450px;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: -20px;
}